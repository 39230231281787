body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --primary: #047d95;
}

.primary-color {
  background-color: var(--primary);
}

.login-container {
  box-shadow: 0px 0px 8px 0px #0000002b;
  border-radius: 16px;
}

input {
  padding: 10px 15px !important;
}

.sign-in-btn button {
  padding: 10px 15px !important;
  border-radius: 4px;
}

.login-container h4 {
  color: var(--primary);
}

.forgotPassword {
  color: var(--primary);
  text-decoration: none;
  cursor: pointer;
}

.login .container .row {
  height: 100vh;
}

.point {
  cursor: pointer;
}

#sidebar .active {
  color: #ffffff !important;
  background-color: #1387C2;
  padding: 8px 12px !important;
  border-radius: 12px;
}

.selectedMenu {
  color: #ffffff !important;
  background-color: #1387C2;
  padding: 8px 12px !important;
  border-radius: 12px;
}




.dashboard-table .table {
  border-collapse: separate;
  border-spacing: 0 15px;
}

.table:not(.table-dark) {
  color: inherit;
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  background-color: transparent;
}

.dashboard-table .table-hover tbody tr {
  background: #fff;
  box-shadow: 0px 0px 4px 0px rgb(0 0 0 / 10%);
}

.dashboard-table .table td:first-child {
  border-left: 2px solid #00000008;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  /* display: flex; */
}

/* .dashboard-table td {
  white-space: nowrap;
} */
.send-credentials {
  background-color: #1387c2 !important;
  color: white !important;
}

.dashboard-table .table td:first-child img {
  max-width: 40px;
  padding-top: 5px;
}

.dashboard-table .table td h3 {
  font-size: 15px;
  color: #000000;
  font-weight: 400;
}

.new-graph h3,
.overview {
  font-size: 15px;
  color: #535252;
  font-weight: 500;
}

.dashboard-table .table td h5 {
  font-size: 13px;
  padding-top: 5px;
  color: #B2B2B2;
  font-weight: 400;
}

.custom-tooltip {
  background-color: rgba(256, 256, 256, 0.9);
  border: 1px solid gray;
  padding: 16px;
}

.custom-tooltip p {
  margin: 0px;
}

.custom-tooltip .xaxis-label {
  font-weight: 600;
}