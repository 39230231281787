.tabs-section {
    display: flex;
    background: #333333;
    padding: 0;
    border-radius: 4px;
}   

.tab {
    width: 150px;
    color: #fff;
    text-align: center;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    border-bottom: 4px solid #333333;
    cursor: pointer;
    /* border-radius: 4px; */
}

.tab.active{
    border-bottom: 4px solid #1387C2;
}