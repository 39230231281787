.mdc-dialog,
.mdc-dialog__scrim {
  position: fixed;
  top: 0;
  left: 0;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  box-sizing: border-box;
  width: 100%;
  height: 100%; }

.mdc-dialog {
  display: none;
  z-index: 7; }
  .mdc-dialog .mdc-dialog__surface {
    background-color: #fff;
    /* @alternate */
    background-color: var(--mdc-theme-surface, #fff); }
  .mdc-dialog .mdc-dialog__scrim {
    background-color: rgba(0, 0, 0, 0.32); }
  .mdc-dialog .mdc-dialog__title {
    color: rgba(0, 0, 0, 0.87); }
  .mdc-dialog .mdc-dialog__content {
    color: rgba(0, 0, 0, 0.6); }
  .mdc-dialog.mdc-dialog--scrollable .mdc-dialog__title,
  .mdc-dialog.mdc-dialog--scrollable .mdc-dialog__actions {
    border-color: rgba(0, 0, 0, 0.12); }
  .mdc-dialog .mdc-dialog__surface {
    min-width: 280px; }
  @media (max-width: 592px) {
    .mdc-dialog .mdc-dialog__surface {
      max-width: calc(100vw - 32px); } }
  @media (min-width: 592px) {
    .mdc-dialog .mdc-dialog__surface {
      max-width: 560px; } }
  .mdc-dialog .mdc-dialog__surface {
    max-height: calc(100vh - 32px); }
  .mdc-dialog .mdc-dialog__surface {
    border-radius: 4px; }

.mdc-dialog__scrim {
  opacity: 0;
  z-index: -1; }

.mdc-dialog__container {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-direction: row;
  justify-content: space-around;
  box-sizing: border-box;
  -webkit-transform: scale(0.8);
          transform: scale(0.8);
  opacity: 0; }

.mdc-dialog__surface {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  -webkit-box-flex: 0;
          flex-grow: 0;
  flex-shrink: 0;
  box-sizing: border-box;
  max-width: 100%;
  max-height: 100%; }
  .mdc-dialog[dir="rtl"] .mdc-dialog__surface,
  [dir="rtl"] .mdc-dialog .mdc-dialog__surface {
    text-align: right; }

.mdc-dialog__title {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1.25rem;
  line-height: 2rem;
  font-weight: 500;
  letter-spacing: 0.0125em;
  text-decoration: inherit;
  text-transform: inherit;
  display: block;
  position: relative;
  flex-shrink: 0;
  box-sizing: border-box;
  margin: 0;
  padding: 0 24px 9px;
  border-bottom: 1px solid transparent; }
  .mdc-dialog__title::before {
    display: inline-block;
    width: 0;
    height: 40px;
    content: "";
    vertical-align: 0; }
  .mdc-dialog[dir="rtl"] .mdc-dialog__title,
  [dir="rtl"] .mdc-dialog .mdc-dialog__title {
    text-align: right; }

.mdc-dialog--scrollable .mdc-dialog__title {
  padding-bottom: 15px; }

.mdc-dialog__content {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  letter-spacing: 0.03125em;
  text-decoration: inherit;
  text-transform: inherit;
  -webkit-box-flex: 1;
          flex-grow: 1;
  box-sizing: border-box;
  margin: 0;
  padding: 20px 24px;
  overflow: auto;
  -webkit-overflow-scrolling: touch; }
  .mdc-dialog__content > :first-child {
    margin-top: 0; }
  .mdc-dialog__content > :last-child {
    margin-bottom: 0; }

.mdc-dialog__title + .mdc-dialog__content {
  padding-top: 0; }

.mdc-dialog--scrollable .mdc-dialog__content {
  padding-top: 8px;
  padding-bottom: 8px; }

.mdc-dialog__content .mdc-list:first-child:last-child {
  padding: 6px 0 0; }

.mdc-dialog--scrollable .mdc-dialog__content .mdc-list:first-child:last-child {
  padding: 0; }

.mdc-dialog__actions {
  display: -webkit-box;
  display: flex;
  position: relative;
  flex-shrink: 0;
  flex-wrap: wrap;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: end;
          justify-content: flex-end;
  box-sizing: border-box;
  min-height: 52px;
  margin: 0;
  padding: 8px;
  border-top: 1px solid transparent; }
  .mdc-dialog--stacked .mdc-dialog__actions {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-align: end;
            align-items: flex-end; }

.mdc-dialog__button {
  /* @noflip */
  margin-left: 8px;
  /* @noflip */
  margin-right: 0;
  max-width: 100%;
  text-align: right; }
  [dir="rtl"] .mdc-dialog__button, .mdc-dialog__button[dir="rtl"] {
    /* @noflip */
    margin-left: 0;
    /* @noflip */
    margin-right: 8px; }
  .mdc-dialog__button:first-child {
    /* @noflip */
    margin-left: 0;
    /* @noflip */
    margin-right: 0; }
    [dir="rtl"] .mdc-dialog__button:first-child, .mdc-dialog__button:first-child[dir="rtl"] {
      /* @noflip */
      margin-left: 0;
      /* @noflip */
      margin-right: 0; }
  .mdc-dialog[dir="rtl"] .mdc-dialog__button,
  [dir="rtl"] .mdc-dialog .mdc-dialog__button {
    text-align: left; }
  .mdc-dialog--stacked .mdc-dialog__button:not(:first-child) {
    margin-top: 12px; }

.mdc-dialog--open,
.mdc-dialog--opening,
.mdc-dialog--closing {
  display: -webkit-box;
  display: flex; }

.mdc-dialog--opening .mdc-dialog__scrim {
  -webkit-transition: opacity 150ms linear;
  transition: opacity 150ms linear; }

.mdc-dialog--opening .mdc-dialog__container {
  -webkit-transition: opacity 75ms linear, -webkit-transform 150ms 0ms cubic-bezier(0, 0, 0.2, 1);
  transition: opacity 75ms linear, -webkit-transform 150ms 0ms cubic-bezier(0, 0, 0.2, 1);
  transition: opacity 75ms linear, transform 150ms 0ms cubic-bezier(0, 0, 0.2, 1);
  transition: opacity 75ms linear, transform 150ms 0ms cubic-bezier(0, 0, 0.2, 1), -webkit-transform 150ms 0ms cubic-bezier(0, 0, 0.2, 1); }

.mdc-dialog--closing .mdc-dialog__scrim,
.mdc-dialog--closing .mdc-dialog__container {
  -webkit-transition: opacity 75ms linear;
  transition: opacity 75ms linear; }

.mdc-dialog--closing .mdc-dialog__container {
  -webkit-transform: scale(1);
          transform: scale(1); }

.mdc-dialog--open .mdc-dialog__scrim {
  opacity: 1; }

.mdc-dialog--open .mdc-dialog__container {
  -webkit-transform: scale(1);
          transform: scale(1);
  opacity: 1; }

.mdc-dialog-scroll-lock {
  overflow: hidden; }
